//export const baseUrlAPI = "http://localhost:8080/api";
// //export const baseUrlAPI = "https://estaraqui.cl/backend/public/api";
// //URL para produccion abajo!
// export const baseUrlAPI = "http://127.0.0.1:8000";
//export const baseUrlAPI = "https://tripartito.net/api";
//export const baseUrlAPI = "http://127.0.0.1:8000/api";
//export const baseUrlAPI = "https://admin.latierradeljuego.cl/backend/public/api";
//export const baseUrlAPI = "https://patagoniablend.cl/patagonia_blend/backend/public/api";
//export const baseUrlAPI = "https://elcometaludo.com/backend/public/api";
//export const baseUrlAPI = "https://qa.tripartito.net/api";
//export const baseUrlAPI = "https://tienda.patagoniablend.cl/api";
export const baseUrlAPI = "https://latierradeljuego.cl/api";