import axios from "axios";
import { baseUrlAPI } from "@/config/api";
axios.defaults.withCredentials = true;
const HOST = baseUrlAPI;
const TIENDA = 'https://latierradeljuego.cl';
//const TIENDA = 'https://tienda.patagoniablend.cl';
//const TIENDA = 'https://qa.tripartito.net';
//const TIENDA = 'https://tripartito.net';
//const TIENDA = 'http://localhost:8080';

export default {
    login(payload = {}) {
        const params = payload.params || {};
        return new Promise((resolve, reject) => {
            axios({
                //url: `https://elcometaludo.com/sanctum/csrf-cookie`,
                //url: `https://tripartito.net/sanctum/csrf-cookie`,
                url: `${TIENDA}/sanctum/csrf-cookie`,
                method: "get",
                withCredentials: true
            }).then(() => {
                axios({
                        //url: `https://tripartito.net/login`,
                        //url: `https://elcometaludo.com/login`,
                        url: `${TIENDA}/login`,
                        method: "post",
                        params
                    })
                    .then(response => {
                        console.log(response);
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        });
    },

    logout(payload = {}) {
        return new Promise((resolve, reject) => {
            axios({
                    //url: `https://tripartito.net/logout`,
                    //url: `https://elcometaludo.com/logout`,
                    url: `${TIENDA}/logout`,
                    method: "post"
                })
                .then(response => resolve(response))
                .catch(error => reject(error));
        });
    },
    user(payload = {}) {
        return new Promise((resolve, reject) => {
            axios({
                    url: `${HOST}/user`,
                    method: "get",
                    withCredentials: true
                })
                .then(response => resolve(response))
                .catch(error => reject(error));
        });
    }
};